import { useAccuracyContext } from "contexts"

export const useAccuracy = () => {
  const { setModalOpen, fetchAccuracy } = useAccuracyContext()

  const toggleModal = (accuracyIds) => {

    if(Array.isArray(accuracyIds)) {
      fetchAccuracy({
        param: JSON.stringify(accuracyIds)
      })
    }

    setModalOpen(currModalOpen => !currModalOpen)
  }

  return { toggleModal }
}