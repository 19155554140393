import { Signin } from "pages"

export const signin = {
  path: 'signin',
  showMenu: false,
  authenticated: false,
  urls: [{
    path: '/',
    component: <Signin />
  }]
}
