import { useMediaQuery, useTheme } from "@mui/material";

export const useBreakpoints = () => {
  const theme = useTheme();

  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));

  const xsUp = useMediaQuery(theme.breakpoints.up('xs'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const smOnly = useMediaQuery(theme.breakpoints.only('sm'));
  const mdOnly = useMediaQuery(theme.breakpoints.only('md'));
  const lgOnly = useMediaQuery(theme.breakpoints.only('lg'));
  const xlOnly = useMediaQuery(theme.breakpoints.only('xl'));

  return {
    xsDown, smDown, mdDown, lgDown, xlDown,
    xsUp, smUp, mdUp, lgUp, xlUp,
    xsOnly, smOnly, mdOnly, lgOnly, xlOnly
  };
};