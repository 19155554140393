import React, { createContext, useContext, useState } from "react"
import { PropTypes } from "prop-types"
import { useAutofieldFetcher } from "services"
import * as Icons from '@mui/icons-material';
import { Form, Table } from "components"
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const IconComponent = ({ iconName, ...props }) => {
  const Icon = Icons[iconName];
  return Icon ? <Icon {...props} /> : null;
};

export const AutofieldContext = createContext({})

export const useAutofieldContext = () => useContext(AutofieldContext)

export const AutofieldProvider = ({ children }) => {
  const { fetchAutofields, autofields } = useAutofieldFetcher()
  const navigate = useNavigate()

  const autofieldsRoutes = autofields?.map(i => ({
    path: i.path.substring(1),
    menu: i.menu,
    icon: <IconComponent iconName={i.icon} />,
    authenticated: true,
    urls: i.methods.map(j => ({
      path: j.method === 'get'
        ? '/'
        : j.method === 'post'
          ? 'create'
          : j.method === 'put'
            ? `:id`
            : j.method,
      component: j.method === 'post'
        ? <Form
          key={i.model}
          endpoint={i.path}
          loadValues={false}
          blocks={i.content}
          submitButtonLabel={i.submitButtonLabel}
          sucessMessage={i.sucessMessage}
          errorMessage={i.errorMessage}
          onSuccess={() => navigate(i.path)}
        />
        : j.method === 'get'
          ? <Table
              key={i.model}
              endpoint={i.path}
              columns={[
                ...i.content
                  .flatMap(i => i.fields.flat().map(i => ({
                    name: i.name,
                    value: i.label
                  }))),
                i.methods.find(k => k.method === 'put')
                  ? {
                      value: 'Editar',
                      action: {
                        value: 'Editar',
                        fn: (id) => navigate(`${i.path}/${id}`),
                        param: 'id'
                      }
                    }
                  : undefined,
                i.methods.find(k => k.method === 'delete')
                  ? {
                      value: 'Remover',
                      action: {
                        value: 'Remover',
                        fn: (id) => {
                          Swal.fire({
                            title: "Excluir",
                            text: 'Tem certeza que deseja excluir o registro?',
                            icon: "alert",
                            showCancelButton: true,
                            confirmButtonText: '$confrimBtn',
                            cancelButtonText: '$cancelBtn',
                            confirmButtonColor: '#36c6d3',
                            cancelButtonColor: '#d33'
                          }).then((result) => {
                            if(result.value){
                              axios.delete(`${i.path}/${id}`).then(() => {
                                fetchAutofields()
                              });
                            }                  
                          })
                        },
                        param: 'id'
                      }
                    }
                  : undefined
              ].filter(i => i)}
            />
          : <Form
              key={i.model}
              endpoint={i.path}
              loadValues={true}
              param={window.location.href.split('/').reverse()[0]}
              blocks={i.content}
              submitButtonLabel={i.submitButtonLabel}
              sucessMessage={i.sucessMessage}
              errorMessage={i.errorMessage}
              onSuccess={() => navigate(i.path)}
            />,
      icon: <IconComponent iconName={j.icon} />,
      menu: j.menu
    }))
  }))

  console.log(autofieldsRoutes)

  return (
    <AutofieldContext.Provider
      value={{
        autofields,
        autofieldsRoutes
      }}
    >
      {children}
    </AutofieldContext.Provider>
  )
}

AutofieldProvider.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}