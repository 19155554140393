import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from 'core'
import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.validateStatus = (status) => status === 401 ? (() => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('timeToExpire')
})() : status >= 200 && status < 300

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <App />
)
