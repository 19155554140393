import validator from 'validator'
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'

const customTypesValidator = {
  email: email => validator.isEmail(email),
  cpf: cpf => !isNaN(cpf) && cpfValidator.isValid(cpf),
  cnpj: cnpj => !isNaN(cnpj) && cnpjValidator.isValid(cnpj),
  phone: phone => !isNaN(phone) && phone.length >= 10 && phone.length <= 13,
  zipCode: zipCode => !isNaN(zipCode) && zipCode.length === 8,
  uuid: uuid => validator.isUUID(uuid),
  uuidArray: uuidArray => Array.isArray(uuidArray) && uuidArray.map(uuid => validator.isUUID(uuid)).find(isValid => !isValid) !== false,
  url: url => validator.isURL(url),
  password: password => password.length > 8,
  date: date => {
    const str = `${date[0]}${date[1]}/${date[2]}${date[3]}/${date[4]}${date[5]}${date[6]}${date[7]}`

    try {
      const t = str.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
      if(t !== null){
        const d = +t[1], m = +t[2], y = +t[3];
        const date = new Date(y, m - 1, d);
        if(date.getFullYear() === y && date.getMonth() === m - 1) {
          return date;   
        }
      }
    
      return null;
    } catch {
      return false
    }
  }
}

const dataTypeValidator = (data, datatype, fieldName, fieldLabel) => {
  let isValid = true
  let error = false

  if (data) {
    isValid = customTypesValidator[datatype]
      ? customTypesValidator[datatype](data)
      : true

    if (!isValid) {
      error = {
        message: `${fieldLabel} inválido`,
        fieldName
      }
    }
  }

  return {
    isValid,
    error
  }
}

export const fieldsValidator = (allFields, fields) => {
  const errors = []

  for (const fieldName in allFields) {
    const nameField = allFields[fieldName].name
    const hasField = Object.prototype.hasOwnProperty.call(fields, fieldName)

    const validType = allFields[fieldName].type
      ? allFields[fieldName].type
      : allFields[fieldName]

    const required = allFields[fieldName].required !== undefined
      ? allFields[fieldName].required
      : false

    const fieldValue = fields[fieldName][nameField]

    const maxLenght = allFields[fieldName].maxLenght
    const minLenght = allFields[fieldName].minLenght

    const fieldLenght = fieldValue
      ? fieldValue.length
      : 0

    const exceedsMaxLenght = maxLenght !== undefined
      ? maxLenght < fieldLenght
      : false

    const lessThenMinLenght = minLenght !== undefined
      ? minLenght > fieldLenght
      : false

    let fieldType = typeof fieldValue

    const fieldValidate = dataTypeValidator(fieldValue, allFields[fieldName].type, nameField, allFields[fieldName].label)

    if (fieldType === 'number' || Number(fieldValue)) {
      if (validType === 'int' && /^\d+$/.test(fieldValue) && Number.isInteger(parseInt(fieldValue))) {
        fieldType = 'int'
      } else if (validType === 'float' && !isNaN(parseFloat(fieldValue))) {
        fieldType = 'float'
      }
    } else if (Array.isArray(fieldValue)) {
      fieldType = 'array'
    }

    if ((!hasField) || (required && !fieldValue)) {
      errors.push({
        message: 'Este campo é obrigatório',
        fieldName: nameField
      })
    }

    if (!((!hasField) || (required && !fieldValue)) && (fieldType !== validType && required) && !fieldValidate.isValid) {
      errors.push({
        message: `${allFields[fieldName].label} inválido`,
        fieldName: nameField,
        receivedValue: fieldType,
        expectedValue: validType
      })
    }

    if (exceedsMaxLenght) {
      errors.push({
        message: `Tamanho máximo excedido ${fieldLenght} de ${maxLenght}`,
        fieldName: nameField
      })
    }

    if (lessThenMinLenght && fieldLenght > 0) {
      errors.push({
        message: `Tamanho mínimo não atingido ${fieldLenght} de ${minLenght}`,
        fieldName: nameField
      })
    }
    if (!fieldValidate.isValid) errors.push(fieldValidate.error)
  }

  if (errors.length > 0) return errors

  return
}
