import React, { createContext, useContext, useState } from "react"
import { PropTypes } from "prop-types"
import { useAccuracyFetcher } from "services"

export const AccuracyContext = createContext({})

export const useAccuracyContext = () => useContext(AccuracyContext)

export const AccuracyProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState()
  const { accuracy, accuracyLoading, fetchAccuracy } = useAccuracyFetcher()

  return (
    <AccuracyContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        accuracy,
        accuracyLoading,
        fetchAccuracy
      }}
    >
      {children}
    </AccuracyContext.Provider>
  )
}

AccuracyProvider.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}