import { Account, Accuracy, Signout } from "pages"
import { AccessTimeTwoTone, AdUnitsOutlined, CircleOutlined, ExitToApp } from '@mui/icons-material'
import { AccuracyProvider } from "contexts"

export const account = {
  path: 'account',
  menu: 'Conta',
  icon: <AdUnitsOutlined />,
  authenticated: true,
  urls: [{
    path: '/',
    menu: 'Cadastro',
    component: <Account />,
    icon: <AccessTimeTwoTone />
  }, {
    path: 'accuracy',
    menu: 'Acuracidade',
    component: <AccuracyProvider><Accuracy /></AccuracyProvider>,
    icon: <CircleOutlined />
  }, {
    path: 'logout',
    menu: 'Logout',
    component: <Signout />,
    icon: <ExitToApp />
  }]
}
