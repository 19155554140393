import { useLogin } from "hooks";
import { useEffect } from "react";

export const Signout = () => {
  const { logout } = useLogin()

  useEffect(() => {
    logout()
  }, [])

  return <></>
}