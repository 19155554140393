import { CircularProgress } from "@mui/material"
import { Menu } from "components"
import { useAutofieldContext, useLoginContext, usePathContext } from "contexts"
import { useEffect } from "react"
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom"
import { home, signup, signin, account, courses } from "routes"
import { useAutofieldFetcher } from "services/autofield"


export const router = [courses, home, account, signup, signin]

const Redirect = ({ to }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(to)
  }, [])

  return <></>
}

export const SystemRouter = () => {
  const { isLoginValid } = useLoginContext()
  const { isLoading } = usePathContext()
  const { autofieldsRoutes } = useAutofieldContext()

  return (
    <Routes>
      {autofieldsRoutes && [...autofieldsRoutes, ...router].map((mainRoute, idxMainRoute) => (
        <Route key={`mainRoute-${idxMainRoute}`} path={mainRoute.path}>
          {
            mainRoute.urls.map((route, idxRoute) => (
              ((mainRoute.authenticated && isLoginValid) ||
              (mainRoute.authenticated === false && !isLoginValid) ||
              (mainRoute.authenticated === undefined))
                ? <Route
                    key={`route-${idxMainRoute}-${idxRoute}`}
                    path={route.path === '/' ? '' : route.path}
                    element={
                      ((route.authenticated && isLoginValid) ||
                      (route.authenticated === false && !isLoginValid) ||
                      (route.authenticated === undefined))
                        ? mainRoute.showMenu === false ? route.component : <Menu>
                            {route.component}
                            {isLoading && <CircularProgress sx={{marginLeft: 'calc(50% - 100px)', marginTop: 'calc(50vh - 150px)', width: 100}} />}
                          </Menu>
                        : route.authenticated === false && isLoginValid
                          ? <Redirect to={'/'} />
                          : <Redirect to={'/signin'} />
                    } />
                : mainRoute.authenticated === false && isLoginValid
                  ? <>
                    <Route key={`route-${idxMainRoute}-${idxRoute}`} path="" element={<Redirect to={'/'} />} />
                    <Route key={`route-${idxMainRoute}-${idxRoute}`} path="*" element={<Redirect to={'/'} />} />
                  </>
                  : <>
                    <Route key={`route-${idxMainRoute}-${idxRoute}`} path="" element={<Redirect to={'/signin'} />} />
                    <Route key={`route-${idxMainRoute}-${idxRoute}`} path="*" element={<Redirect to={'/signin'} />} />
                  </>
            ))
          }
        </Route>
      ))}
      <Route path="*" element={<div>404</div>} />
    </Routes>
  )
}
