import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { usePathContext } from "contexts";
import { useNavigate, useParams } from "react-router-dom";
import { useCoursesFetcher } from "services";

export const Courses = () => {
  const { id } = useParams()
  const { courses } = useCoursesFetcher(id)
  const { setIsLoading } = usePathContext()
  const navigate = useNavigate()

  setIsLoading(!courses)

  return (
    <Grid container>
      {courses?.map((course) => (
        <Grid item md={8} sm={12}>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={`${process.env.REACT_APP_API_URL}/sti/file/${course.image}`}
              title={course.courseName}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {course.courseName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {course.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" variant="contained" onClick={() => navigate(`./../classes/${course.id}`)}>Ver Aulas</Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}