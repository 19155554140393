import { useCityFetcher, useStateFetcher } from "services"
import { Form } from "components"
import { useNavigate } from "react-router-dom"

export const Signup = () => {
  const { states } = useStateFetcher()
  const { cities, fetchCities } = useCityFetcher()
  const navigate = useNavigate()
  
  return (
    <Form
      endpoint="/accounts"
      blocks={[{
        name: 'Cadastrar Usuário',
        fields: [{
          label: 'Nome',
          name: 'name',
          type: 'text',
          maxLenght: 100,
          minLenght: 2,
          required: true
        }, {
          label: 'CPF',
          name: 'cpf',
          type: 'cpf',
          mask: '000.000.000-00',
          maxLenght: 11,
          minLenght: 11,
          required: true
        }, {
          label: 'Telefone',
          name: 'phone',
          type: 'phone',
          mask: '(00)00000-0000',
          maxLenght: 11,
          minLenght: 11,
          required: true
        }]
      }, {
        name: 'Dados de login',
        fields: [[{
          label: 'Email',
          name: 'email',
          type: 'email',
          maxLenght: 120,
          minLenght: 2,
          required: true
        }, {
          label: 'Senha',
          name: 'password',
          type: 'password',
          maxLenght: 100,
          minLenght: 8,
          required: true
        }]]
      }, {
        name: 'Endereço',
        fields: [[{
          label: 'Cep',
          name: 'zipCode',
          type: 'zipCode',
          required: true,
          mask: '00000-000'
        }, {
          label: 'Estado',
          name: 'state',
          type: 'select',
          required: true,
          options: states?.map((state) => ({
            label: state.name,
            value: state.id
          }))
        }, {
          label: 'Cidade',
          name: 'cityId',
          type: 'select',
          required: true,
          fetch: {
            fetcher: fetchCities,
            field: 'state'
          },
          options: cities?.map((city) => ({
            label: city.name,
            value: city.id
          }))
        }], [{
          label: 'Bairro',
          name: 'district',
          type: 'text',
          required: true,
          minLenght: 2,
          maxLenght: 100
        }, {
          label: 'Rua',
          name: 'street',
          type: 'text',
          required: true,
          minLenght: 2,
          maxLenght: 100
        }, {
          label: 'Número',
          name: 'number',
          type: 'text',
          required: true,
          minLenght: 2,
          maxLenght: 100
        }]]
      }]}
      submitButtonLabel="Cadastrar"
      sucessMessage="O usuário foi cadastrado com sucesso"
      errorMessage="O usuário cadastrado já existe"
      onSuccess={() => navigate('/signin')}
    />
  )
}