import React from "react"
import { Markdown } from "./components"
import { checkJson } from "utils"

export const Messenger = ({ messages }) => (
  <div>
    {
      messages?.map((msg, idx) =>
        <div style={{
          backgroundColor: 'grey',
          color: 'white',
          padding: '5px 20px 5px 20px',
          borderRadius: '10px',
          marginBottom: '20px',
          width: '55%',
          marginLeft: msg.from === 'user' && '45%',
        }}>
          {msg.message.split('\n').map(msgPart => (
            <p>
              {checkJson(msgPart)?.image_file?.file_id
                ? <img alt="Gráfico" style={{width: '80%', marginLeft: '10%'}} src={`${process.env.REACT_APP_API_URL}/sti/file/${checkJson(msgPart)?.image_file?.file_id}`} onLoad={() => window.scrollTo(0, document.body.scrollHeight)} />
                : <Markdown children={msgPart.replace(/\\\[/g, '$$$').replace(/\\\]/g, '$$$').replace(/\\\(/g, '$$$').replace(/\\\)/g, '$$$')} />
              }
            </p>
          ))}
        </div>
      )
    }
  </div>
)

