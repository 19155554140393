import { useLoginContext } from "contexts"
import { useNavigate } from "react-router-dom"

export const useLogin = () => {
  const { setIsLoginValid } = useLoginContext()
  const navigate = useNavigate()

  const login = (accessToken) => {
    const today = new Date()

    today.setHours(today.getHours() + process.env.REACT_APP_LOGIN_EXPIRE_HOURS)

    const timeToExpire = today.getTime()

    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('timeToExpire', timeToExpire)

    setIsLoginValid(true)
    navigate('/')
  }

  const logout = () => {
    setIsLoginValid(false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('timeToExpire')
  }

  return {
    login,
    logout
  }
}