import { Modal as MuiModal, Box, Typography } from '@mui/material';
import { Messenger } from 'components';
import { useAccuracyContext } from 'contexts';
import { useAccuracy } from 'hooks';
import { capitalize } from 'utils';

export const Modal = () => {
  const { modalOpen, accuracy } = useAccuracyContext()
  const { toggleModal } = useAccuracy()

  return (
    <MuiModal
      open={modalOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '94%',
        overflow: 'auto',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {capitalize(accuracy?.accuracy?.context)}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2}}>
          {accuracy?.messages?.map((messages, idx) => 
            <div>
              <Messenger key={`messenger-${idx}`} messages={messages} />
              {idx !== accuracy.messages.length - 1 && <hr style={{marginBottom: 20}} />}
            </div>
          )}
        </Typography>
      </Box>
    </MuiModal>
  );
}
