import { Table } from "components"
import { useAccuracy } from "hooks"
import { Modal } from "./components"

export const Accuracy = () => {
  const { toggleModal } = useAccuracy()
  return (
    <>
      <Modal />
      <Table
        endpoint="/accounts/accuracies"
        columns={[{
          name: 'context',
          value: 'Contexto'
        }, {
          name: 'correct',
          value: 'Corretas'
        }, {
          name: 'wrong',
          value: 'Erradas'
        }, {
          value: 'Ação',
          action: {
            value: 'Visualizar',
            fn: (ids) => toggleModal(ids),
            param: 'ids'
          }
        }]}
      />
    </>
  )
}