import React, { createContext, useContext, useEffect, useState } from "react"
import { PropTypes } from "prop-types"

export const LoginContext = createContext({})

export const useLoginContext = () => useContext(LoginContext)

export const LoginProvider = ({ children }) => {
  const timeToExpire = localStorage.getItem('timeToExpire')
  const [isLoginValid, setIsLoginValid] = useState(timeToExpire ? Date.now() < parseInt(timeToExpire) : false)

  useEffect(() => {
    setInterval(() => {
      const timeToExpireLogin = localStorage.getItem('timeToExpire')

      if(timeToExpireLogin) {
        setIsLoginValid(Date.now() < parseInt(timeToExpireLogin))
      } else {
        setIsLoginValid(false)
      }
    }, 10000)
  }, [])

  return (
    <LoginContext.Provider
      value={{
        isLoginValid,
        setIsLoginValid
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

LoginProvider.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}