import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { useBreakpoints, useForm } from 'hooks';
import { MuiFileInput } from 'mui-file-input';
import { FormProvider, useFormContext } from 'contexts';

const FetcherComponent = ({ formData, setFormData, field, autoRemoveMask }) => {
  const {lastValues, setLastValues, formLoading, setFormLoading, setDisabledFiels, loadFinished, sosLoadFinished, setSosLoadFinished} = useFormContext()

  useEffect(() => {
    const fieldName = field?.fetch?.field
    if(formData[fieldName] && lastValues[fieldName] !== formData[fieldName]) {
      setFormLoading(true)
      setDisabledFiels((currDisableFields) => ([
        ...currDisableFields,
        field.name
      ]))
      field?.fetch?.fetcher({ param: autoRemoveMask(fieldName)}).then(() => {
        setFormLoading(false)
        setDisabledFiels((currDisableFields) => currDisableFields.filter(currDisableField => currDisableField !== field.name))
      })
      setTimeout(() => {
        setSosLoadFinished(true)
      }, 500)
      if(loadFinished && !formLoading && sosLoadFinished) {
        setFormData((currFormData) => ({
          ...currFormData,
          [field.name]: null
        }))
      }
    }

    setLastValues(formData)
  }, [formData])

  return <></>
}

const FieldInputs = ({ allDisabled, field, formErrors, disabledFiels, onChangeValue, formData, getFieldType }) => (
  field.type === 'checkbox' ?
    <FormControl error={!!formErrors[field.name]?.message}>
      <FormControlLabel
        sx={{color: '#666 !important'}}
        control={
          <Checkbox
            disabled={allDisabled || field.disabled || disabledFiels.find(disabledField => disabledField === field.name)}
            name={field.name}
            checked={formData[field.name] || ''}
            onChange={onChangeValue(field.name)}
          />
        }
        label={`${field.label}${field.required ? '*' : ''}`}
      />
      {formErrors[field.name]?.message && <FormHelperText>{formErrors[field.name]?.message}</FormHelperText>}
    </FormControl>
  :
    field.type === 'radiogroup' ?
      <FormControl error={!!formErrors[field.name]?.message}>
        <FormLabel component="legend">{`${field.label}${field.required ? '*' : ''}`}</FormLabel>
        <RadioGroup row name={field.name} value={formData[field.name] || ''} onChange={onChangeValue(field.name)}>
          {field.options?.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio disabled={allDisabled || field.disabled || disabledFiels.find(disabledField => disabledField === field.name)} name={option.name} />}
              label={option.label}
              sx={{color: '#666 !important'}}
            />
          ))}
        </RadioGroup>
        {formErrors[field.name]?.message && <FormHelperText>{formErrors[field.name]?.message}</FormHelperText>}
      </FormControl>
    : field.type === 'file' ? 
        <>
          <MuiFileInput
            error={!!formErrors[field.name]?.message}
            label={`${field.label}${field.required ? '*' : ''}`}
            value={formData[field.name] || ''}
            onChange={onChangeValue(field.name)}
            inputProps={{accept: field?.accept?.join(', ')}}
            fullWidth
            helperText={formErrors[field.name]?.message}
            multiple={field.multiple}
            disabled={allDisabled || field.disabled || disabledFiels.find(disabledField => disabledField === field.name)}
            InputProps={{}}
          />
          {
            formData[field.name] && !(formData[field.name] instanceof File) &&
              <a rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#1976d2', marginTop: '15px', marginLeft: '-145px', float: 'right', zIndex: '999', position: 'absolute', width: 130 }} target='_blank' href={`${process.env.REACT_APP_API_URL}/files/${formData[field.name]}`}>Visualizar Arquivo</a>
          }
        </>
      : 
        <TextField
          error={!!formErrors[field.name]?.message}
          helperText={formErrors[field.name]?.message}
          value={formData[field.name] || ''}
          name={field.name}
          onChange={onChangeValue(field.name)}
          type={getFieldType(field)}
          label={`${field.label}${field.required ? '*' : ''}`}
          fullWidth
          {...field.config}
          select={field.type === 'select'}
          multiple={field.multiple}
          disabled={allDisabled || field.disabled || disabledFiels.find(disabledField => disabledField === field.name)}
        >
          {field.type === 'select' && field.options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
)

const FormConstructor = ({ endpoint, param, method, loadValues, onLoadValues, allowDelete, blocks, submitButtonLabel, sucessMessage, errorMessage, children, onSuccess, onError, allDisabled, deleteButtonLabel, deleteSucessMessage, deleteErrorMessage, onDeleteSuccess, onDeleteError }) => {
  const {
    formData,
    setFormData,
    formErrors,
    onChangeValue,
    onSubmit,
    getFieldType,
    autoRemoveMask,
    loading,
    values,
    onDelete,
    deleteLoading
  } = useForm(endpoint, param, method, loadValues, onLoadValues, !blocks[0] ? blocks.fields.flat() : blocks.map(block => block.fields).flat(), sucessMessage, errorMessage, onSuccess, onError, deleteSucessMessage, deleteErrorMessage, onDeleteSuccess, onDeleteError)
  const getBlocks = !blocks[0] ? [blocks] : blocks
  const { mdUp } = useBreakpoints()
  const { disabledFiels } = useFormContext()

  return (
    <Box component="form" noValidate autoComplete="off">
      <Paper style={{ backgroundColor: '#fff', padding: 20, color: '#000', marginBottom: 50 }}>
        {
          !(!allDisabled || (allDisabled && values))
            ?
              <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
                <CircularProgress size='100px' />
              </Box>
            :
              <>
                <Grid container spacing={2}>
                  {getBlocks.map((block, blockIdx) => (
                    <React.Fragment key={`block-${blockIdx}`}>
                      <Typography marginLeft="15px" marginTop="20px" fontSize={25} color="#666" width="100%" textAlign="left">{block.name}</Typography>
                      <Divider sx={{width: 'calc(100% - 15px)', marginLeft: '15px'}} />
                      {block.fields.map((field, fieldIdx) =>
                        Array.isArray(field) ? (
                          field.map((child, childIdx) => (
                            <React.Fragment key={`child${fieldIdx}${childIdx}`}>
                              {child?.fetch?.field && <FetcherComponent field={child} formData={formData} setFormData={setFormData} autoRemoveMask={autoRemoveMask} />}
                              {child.spacing && mdUp && <Grid item md={child.spacing} />}
                              <Grid item xs={12} md={child.grid || (12 / field.length)}>
                              <Tooltip title={child.tooltip}>
                                <FieldInputs allDisabled={!!allDisabled} field={child} formErrors={formErrors} disabledFiels={disabledFiels} onChangeValue={onChangeValue} formData={formData} getFieldType={getFieldType} />
                                </Tooltip>
                              </Grid>
                            </React.Fragment>
                          ))
                        ) : (
                          <React.Fragment key={`field-${fieldIdx}`}>
                            {field?.fetch?.field && <FetcherComponent field={field} formData={formData} setFormData={setFormData} autoRemoveMask={autoRemoveMask} />}
                            {field.spacing && mdUp && <Grid item xs={0} md={field.spacing} />}
                            <Grid item xs={12} md={field.grid || 12}>
                            <Tooltip title={field.tooltip}>
                              <FieldInputs allDisabled={!!allDisabled} field={field} formErrors={formErrors} disabledFiels={disabledFiels} onChangeValue={onChangeValue} formData={formData} getFieldType={getFieldType} />
                              </Tooltip>
                            </Grid>
                            {field.spacing && field.grid && mdUp && <Grid item md={12 - (field.spacing + field.grid)} />}
                          </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
                {
                  !allDisabled &&
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                      <Button variant="contained" size="large" onClick={onSubmit} disabled={(deleteLoading || loading) || Object.values(formErrors)?.find(error => error?.message)}>
                        {(deleteLoading || loading) ? <CircularProgress /> : submitButtonLabel}
                      </Button>
                    </div>
                }
                {
                  allowDelete &&
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                      <Button variant="contained" size="large" sx={{ backgroundColor: '#c40b0a' }} disabled={deleteLoading || loading} onClick={onDelete}>
                        {(deleteLoading || loading) ? <CircularProgress /> : deleteButtonLabel}
                      </Button>
                    </div>
                }
                {children}
              </>
        }
      </Paper>
    </Box>
  )
}

export const Form = (data) => (
  <FormProvider>
    <FormConstructor {...data} />
  </FormProvider>
)