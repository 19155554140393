import React, { createContext, useContext, useEffect, useState, useRef } from "react"
import { PropTypes } from "prop-types"
import { useMessageFetcher } from "services"

export const ChatContext = createContext({})

export const useChatContext = () => useContext(ChatContext)

export const ChatProvider = ({ children }) => {
  const { messages, fetchMessages } = useMessageFetcher()
  const [chat, setChat] = useState([])
  const [message, setMessage] = useState('')
  const [lockSendMessage, setLockSendMessage] = useState(false)
  const [socket, setSocket] = useState('')
  const messageTimeoutRef = useRef(null)

  useEffect(() => {
    if(!socket) {
      if(!messages) {
        fetchMessages()
      }

      const socketStart = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL)

      socketStart.addEventListener('open', (event) => {
        console.log('Connected to WS Server')
      })
    
      socketStart.addEventListener('message', (event) => {
        console.log('Message from server ', event.data)
        setLockSendMessage(true)

        clearTimeout(messageTimeoutRef.current)
        messageTimeoutRef.current = setTimeout(() => {
          setLockSendMessage(false)
        }, 5000)

        setChat((currChat) => {
          let lastChat = [...currChat].reverse()[0]
        
          if (lastChat?.from === 'sti') {
            lastChat.message += event.data
        
            return [
              ...currChat.slice(0, -1),
              lastChat
            ];
          } else {
            return [
              ...currChat,
              {
                from: 'sti',
                message: event.data
              }
            ]
          }
        })
      })

      socketStart.addEventListener('close', () => {
        clearTimeout(messageTimeoutRef.current)
        setSocket('')
      })

      setSocket(socketStart)
    }
  }, [socket, fetchMessages])

  useEffect(() => {
    if(messages?.[0]) {
      setChat((currChat) => [
        ...currChat,
        ...messages
      ])
    }
  }, [messages])

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [chat])

  return (
    <ChatContext.Provider
      value={{
        chat,
        setChat,
        message,
        setMessage,
        socket,
        lockSendMessage,
        setLockSendMessage
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

ChatProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
