import { useFormContext } from "contexts";
import { useRequest } from "hooks";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { fieldsValidator } from "utils";

export const useForm = (endpoint, param, method, loadValues, onLoadValues, fields, sucessMessage, errorMessage, onSuccess, onError, deleteSucessMessage, deleteErrorMessage, onDeleteSuccess, onDeleteError) => {
  const validations = fields.flat();
  const [formData, setFormData] = useState(validations.map(validation => ({ [validation.name]: null })).reduce((result, currentObject) => {
    for (const key in currentObject) {
      if (currentObject.hasOwnProperty(key)) {
        result[key] = currentObject[key]
      }
    }
    return result
  }, {}))
  const [formErrors, setFormErrors] = useState({})
  const [systemLoading, setSystemLoading] = useState(true)
  const [reqFinished, setReqFinished] = useState(false)
  const { formLoading, setLoadFinished } = useFormContext()
  console.log(param)
  const { fetchValues, values, valuesLoading } = useRequest({
    model: 'values',
    endpoint: `${endpoint}${param ? `/${param}` : ''}`,
    method: 'get'
  })
  const { fetchReq, reqLoading, req, reqError } = useRequest({
    model: 'req',
    endpoint: `${endpoint}${param ? `/${param}` : ''}`,
    method: method || ((reqFinished || (loadValues && values)) && 'put') || 'post',
  })
  const { fetchRemove, removeLoading, remove, removeError } = useRequest({
    model: 'remove',
    endpoint: `${endpoint}${param ? `/${param}` : ''}`,
    method: 'delete',
  })

  useEffect(() => {
    if(loadValues) {
        fetchValues({
          body: loadValues.body
        }).then(() => {
          setLoadFinished(true)
        })
    } else {
      setSystemLoading(false)
      setLoadFinished(true)
    }
  }, [])

  useEffect(() => {
    if(values) {
      onLoadValues?.(values)
    }
    setFormData((currFormData) => {
      const newForData = {}
      
      for(const idx in  currFormData) {
        const validation = validations.find(validation => validation.name === idx)

         const newValue = validation.defaultValue || values?.[idx] || null

        if(validation.mask && newValue) {
          newForData[idx] = applyMask(newValue, validation.mask)
        } else {
          newForData[idx] = newValue
        }
      }

      setSystemLoading(false)

      return newForData
    })
  }, [values, loadValues])

  useEffect(() => {
    if(req) {
      setReqFinished(true)
      onLoadValues?.(req)
      if(sucessMessage) {
        Swal.fire({
          title: "Sucesso!",
          text: sucessMessage,
          icon: "success"
        }).then(() => {
          onSuccess?.(req)
        })
      } else {
        onSuccess?.(req)
      }
    }
  }, [req])

  useEffect(() => {
    if(reqError) {
      if(errorMessage) {
        Swal.fire({
          title: "Erro!",
          text: errorMessage,
          icon: "error"
        }).then(() => {
          onError?.(reqError)
        })
      } else {
        onError?.(reqError)
      }
    }
  }, [reqError])

  useEffect(() => {
    if(remove) {
      if(deleteSucessMessage) {
        Swal.fire({
          title: "Sucesso!",
          text: deleteSucessMessage,
          icon: "success"
        }).then(() => {
          onDeleteSuccess?.(remove)
        })
      } else {
        onDeleteSuccess?.(remove)
      }
    }
  }, [remove])

  useEffect(() => {
    if(removeError) {
      if(deleteErrorMessage) {
        Swal.fire({
          title: "Error!",
          text: deleteErrorMessage,
          icon: "error"
        }).then(() => {
          onDeleteError?.(removeError)
        })
      } else {
        onDeleteError?.(removeError)
      }
    }
  }, [removeError])

  const getFieldType = (field) => ['int', 'float'].includes(field.type)
    ? 'number'
    : ['text', 'password'].includes(field.type)
      ? field.type
      : 'text';
  const applyMask = (value, mask) => {
    let result = '';
    let valueIndex = 0;
  
    for (let i = 0; i < mask.length; i++) {
      if (valueIndex >= value.length) {
        break;
      }
  
      const maskCharacter = mask[i];
      let valueCharacter = value[valueIndex];
  
      if (maskCharacter === '0') {
        if (/\d/.test(valueCharacter)) {
          result += valueCharacter;
          valueIndex++;
        } else {
          valueIndex++;
          i--;
          continue;
        }
      } else if (maskCharacter === 'A') {
        if (/[A-Za-z]/.test(valueCharacter)) {
          result += valueCharacter;
          valueIndex++;
        } else {
          valueIndex++;
          i--;
          continue;
        }
      } else if (maskCharacter === '*') {
        result += valueCharacter;
        valueIndex++;
      } else {
        result += maskCharacter;
      }
    }
  
    return result;
  }
  
  const removeMask = (maskedString, mask) => {
    const regex = new RegExp(`[${mask.replace(/0|A|\*/g, '')}]`, 'g');
    return maskedString.replace(regex, '');
  }
  const autoRemoveMask = (fieldName) => {
    const mask = validations.find(validation => validation.name === fieldName).mask
    return mask && formData[fieldName]
      ? removeMask(formData[fieldName], mask)
      : formData[fieldName]
  }
  
  const onChangeValue = useCallback((fieldName) => (e) => {
    setFormData((formDataValues) => {
      const validation = validations.find(validation => validation.name === fieldName)
      const mask = validation.mask
      const nextValue = mask
        ? applyMask(e.target.value, mask)
        : validation.type === 'checkbox'
          ? e.target.checked
          : validation.type === 'file'
            ? e
            : e.target.value
  
      setFormErrors((formErrorsCurr) => ({
        ...formErrorsCurr,
        [fieldName]: fieldsValidator(validations.filter((validation) => validation.name === fieldName), [
          { [fieldName]: mask && nextValue ? removeMask(nextValue, mask) : nextValue },
        ])?.[0],
      }));
  
      return {
        ...formDataValues,
        [fieldName]: nextValue
      }
    });
  }, [setFormData, setFormErrors, validations]);
  
  const onSubmit = () => {
    let errorsObj = {}
    const dataKeys = Object.keys(formData)
    const formDataValues = {}
  
    for(const key of dataKeys) {
      const mask = validations.find(validation => validation.name === key).mask
      const value = mask && formData[key]
        ? removeMask(formData[key], mask)
        : formData[key]
      const error = fieldsValidator(validations.filter((validation) => validation.name === key), [
        { [key]: value },
      formDataValues[key] = value
      ])?.[0]
  
      errorsObj[key] = error
    }
  
    setFormErrors(errorsObj);

    if(!Object.values(errorsObj)?.find(error => error?.message)) {
      fetchReq({
        body: formDataValues
      })
    }
  };

  const onDelete = () => {
    fetchRemove()
  }

  return {
    formData,
    setFormData,
    formErrors,
    onChangeValue,
    onSubmit,
    getFieldType,
    autoRemoveMask,
    loading: reqLoading || valuesLoading || systemLoading || formLoading,
    values,
    onDelete,
    deleteLoading: removeLoading
  }
}
