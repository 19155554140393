import { Classes, Courses, Trails } from "pages"
import { AccessTimeTwoTone, GolfCourse } from '@mui/icons-material'

export const courses = {
  path: '/',
  menu: 'Cursos',
  icon: <GolfCourse />,
  authenticated: true,
  urls: [{
    path: '/',
    component: <Trails />,
    icon: <AccessTimeTwoTone />
  }, {
    path: 'courses/:id',
    component: <Courses />
  }, {
    path: 'courses/classes/:id',
    component: <Classes />
  }]
}
