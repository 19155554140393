import { Box, Button, TextField } from "@mui/material"
import { useChatContext } from "contexts"
import { useBreakpoints, useChat } from "hooks"
import React from "react"
import { Markdown } from "./components"
import { checkJson } from "utils"
import { Messenger } from "components"
import { Send } from "@mui/icons-material"

export const Chat = () => {
  const { chat, message, setMessage, lockSendMessage } = useChatContext()
  const { sendMessage } = useChat()
  const { smUp } = useBreakpoints()

  return (
    <Box>
      <div style={{paddingBottom: 100}}>
        <Messenger messages={chat} />
      </div>
      <br />
      <div style={{
        position: 'fixed',
        bottom: 0,
        paddingTop: 20,
        width: `calc(100% ${smUp ? '- 280px' : '- 30px'})`,
        backgroundColor: 'white',
        paddingBottom: 20
      }}>
        <TextField 
          multiline
          rows={4}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{width: 'calc(100% - 120px)'}}
        />
        <Button
          onClick={sendMessage}
          sx={{width: 100, marginLeft: 2, height: 125}}
          disabled={lockSendMessage || !message}
          variant="contained"
        >
          <Send />
        </Button>
      </div>
    </Box>
  )
}
