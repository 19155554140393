import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { usePathContext } from "contexts";
import { useBreakpoints, useLogin } from "hooks";
import { useNavigate } from "react-router-dom";
import { useTrailsFetcher } from "services";

export const Trails = () => {
  const { trails } = useTrailsFetcher()
  const navigate = useNavigate()
  const { setIsLoading } = usePathContext()

  setIsLoading(!trails)

  return (
    <Grid container>
      {trails?.map((trail) => (
        <Grid item md={8} sm={12}>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={`${process.env.REACT_APP_API_URL}/sti/file/${trail.image}`}
              title={trail.trailName}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {trail.trailName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {trail.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" variant="contained" onClick={() => navigate(`/courses/${trail.id}`)}>Ver Cursos</Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}