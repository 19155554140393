import { Breadcrumbs, Button } from '@mui/material';
import { usePathContext } from 'contexts';
import { usePath } from 'hooks/usePath/usePath';

export const Breadcrumb = () => {
  const { path } = usePathContext()
  const { goToMenu } = usePath()
  const handleClick = (event) =>  {
    event.preventDefault();
  }
  
  return (
    <div onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" sx={{color: 'white'}}>
        {path.breadcrumbs.map((breadcrumb, idx) => (
          <Button sx={{color: 'white !important'}} key={`breadcrumb-${idx}`} underline="hover" color="inherit" onClick={() => goToMenu(breadcrumb.url)}>
            {breadcrumb.text}
          </Button>
        ))}
      </Breadcrumbs>
    </div>
  );
}