import { toPng } from "html-to-image"

export const htmlToPng = (elementRef) => 
  toPng(elementRef.current, { cacheBust: false })
    .then((dataUrl) => {
      const link = document.createElement("a")
      link.download = "qrCode.png"
      link.href = dataUrl
      link.click()
    })
