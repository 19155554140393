import { useChatContext } from "contexts"

export const useChat = () => {
  const { message, setMessage, setLockSendMessage, setChat, socket } = useChatContext()

  const sendMessage = () => {
    setLockSendMessage(true)
    setChat((currChat) => [
      ...currChat,
      {
        from: 'user',
        message
      }
    ])
    socket.send(JSON.stringify({
      accessToken: localStorage.getItem('accessToken'),
      message
    }))

    setMessage('')
  }

  return {
    sendMessage
  }
}