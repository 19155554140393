import { Box, Button, Grid } from "@mui/material";
import { Form } from "components";
import { useBreakpoints, useLogin } from "hooks";
import { useNavigate } from "react-router-dom";

export const Signin = () => {
  const { mdUp } = useBreakpoints()
  const { login } = useLogin()
  const navigate = useNavigate()

  return (
    <Grid container sx={{ marginTop: '50px' }}>
      {mdUp && <Grid item md={2} />}
      <Grid item md={8} sm={12}>
        <Form
          endpoint="/login"
          blocks={[{
            name: 'Login',
            fields: [{
              label: 'Email',
              name: 'email',
              type: 'email',
              maxLenght: 120,
              minLenght: 2,
              required: true
            }, {
              label: 'Senha',
              name: 'password',
              type: 'password',
              maxLenght: 100,
              minLenght: 8,
              required: true
            }]
          }]}
          submitButtonLabel="Login"
          errorMessage="Usuário ou senha inválidos"
          onSuccess={(data) => login(data.accessToken)}
        >
          <Box sx={{textAlign: 'center', marginTop: 1}}>
            <Button onClick={() => navigate('/signup')} variant="contained" size="large">Cadastre-se</Button>
          </Box>
        </Form>
      </Grid>
    </Grid>
  )
}