import React, { createContext, useContext, useState } from "react"
import { PropTypes } from "prop-types"

export const FormContext = createContext({})

export const useFormContext = () => useContext(FormContext)

export const FormProvider = ({ children }) => {
  const [lastValues, setLastValues] = useState({})
  const [formLoading, setFormLoading] = useState(false)
  const [disabledFiels, setDisabledFiels] = useState([])
  const [loadFinished, setLoadFinished] = useState(false)
  const [sosLoadFinished, setSosLoadFinished] = useState(false)

  return (
    <FormContext.Provider
      value={{
        lastValues,
        setLastValues,
        formLoading,
        setFormLoading,
        disabledFiels,
        setDisabledFiels,
        loadFinished,
        setLoadFinished,
        sosLoadFinished,
        setSosLoadFinished
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

FormProvider.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}