import { Chat } from "pages"
import { Chat as ChatIcon } from '@mui/icons-material'
import { ChatProvider } from "contexts"

export const home = {
  path: 'chat',
  menu: 'Chat',
  icon: <ChatIcon />,
  authenticated: true,
  urls: [{
    path: '/',
    component: (
      <ChatProvider>
        <Chat />
      </ChatProvider>
    )
  }]
}
