import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { usePath } from 'hooks'

export const Item = ({ disabled, sx, urlArr, text, icon }) => {
  const { goToMenu } = usePath()

  return (
    <ListItem disablePadding>
      <ListItemButton style={disabled && {cursor: 'default'}} onClick={() => !disabled && goToMenu(urlArr)} sx={sx}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  )
}
