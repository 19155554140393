import { Button, Card, CardContent, Grid, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useClassesFetcher, useStartClassFetcher } from "services"

export const Classes = () => {
  const { id } = useParams()
  const { course, setCourse } = useClassesFetcher(id)
  const [isLoaded, setIsLoaded] = useState(false)
  const [selectedClass, setSelectedClass] = useState(course?.modules[0]?.classes[0])
  const { fetchStartClass } = useStartClassFetcher()
  const updateIsCompleted = (classItem) => setCourse((currCourse) => ({ ...currCourse, modules: currCourse.modules.map(j => ({...j, classes: j.classes.map(k => k.id === classItem.id ? {...k, isCompleted: 1} : k )})) })) 

  useEffect(() => {
    if(course && !isLoaded) {
      setIsLoaded(true)
      const currClass = course?.modules[0]?.classes[0]
      setSelectedClass(currClass)
      if(!currClass.isCompleted) {
        fetchStartClass({
          body: {
            classId: currClass.id
          }
        })
        updateIsCompleted(currClass)
      }
    }
  }, [course])

  const handleClassClick = (classItem) => {
    setSelectedClass(classItem)
    if(!classItem.isCompleted) {
      fetchStartClass({
        body: {
          classId: classItem.id
        }
      })
      updateIsCompleted(classItem)
    }
  }

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', pr: 2 }}>
        <Card sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <CardContent>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {course?.courseName}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {course?.description}
            </Typography>
          </CardContent>

          <video 
            width="100%" 
            controls 
            controlsList="nodownload"
            src={selectedClass?.file && `${process.env.REACT_APP_API_URL}/sti/file/${selectedClass?.file}`}
          >
            Seu navegador não suporta a tag de vídeo.
          </video>

          <CardContent>
            <Typography variant="h5" sx={{ mt: 2 }}>
              {selectedClass?.className}
            </Typography>
            <Typography variant="body1">{selectedClass?.description}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Duração: {selectedClass?.duration} minutos
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
        {course?.modules?.map((module) => (
          <Card key={module.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {module.moduleName}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {module.description}
              </Typography>
              {module.classes.map((classItem) => (
                <Button
                  key={classItem.id}
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: 1,
                    justifyContent: "flex-start",
                    backgroundColor: classItem.id === selectedClass?.id
                      ? "lightgray" 
                        : classItem.isCompleted
                          ? '#5bb53f'
                          : "white",
                    color: classItem.isCompleted && classItem.id !== selectedClass?.id
                      ? 'white'
                      : undefined,
                  }}
                  onClick={() => handleClassClick(classItem)}
                >
                  {classItem.className}
                </Button>
              ))}
            </CardContent>
          </Card>
        ))}
      </Grid>
    </Grid>
  )
}
