import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Table as MuiTable, Typography, styled, tableCellClasses, Button, CircularProgress, Box, Tooltip } from "@mui/material";
import { useRequest } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

  
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#7d7d7d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const Table = ({ endpoint, columns }) => {
  const [systemLoading, seSystemLoading] = useState(true)
  const navigate = useNavigate()
  const { req, fetchReq } = useRequest({
    endpoint,
    method: 'get',
    model: 'req'
  })

  useEffect(() => {
    fetchReq().then(() => {
      seSystemLoading(false)
    })
  }, [])

  return (
    <TableContainer component={Paper} sx={{overflowY: 'hidden'}}>
      <MuiTable sx={{maxWidth: '100%'}}>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) =>
              <StyledTableCell key={`thead-${idx}`} sx={column.action && { width: '150px' }}>{column.value}</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {req?.map((value, idxReq) =>
            <StyledTableRow sx={{maxWidth: '100% !important'}} key={`tbody-${idxReq}`}>
              {columns.map((column, idxColumn) =>
                <StyledTableCell key={`tcell-${idxColumn}`}>
                  {column.name
                    ?
                      <Tooltip title={column.showTooltip && (column.format ? column.format(value[column.name]) : value[column.name])}>
                        <Typography sx={{maxWidth: '310px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {column.format ? column.format(value[column.name]) : value[column.name]}
                        </Typography>
                      </Tooltip>
                    :
                      column.action
                      ?
                        <Button size="large" variant="contained" onClick={() => column.action.navigate
                          ? navigate(`${column.action.navigate}/${value[column.action.param]}`)
                          : column.action.fn
                            ? column.action.fn(value[column.action.param])
                            : undefined
                        }>
                          {column.action.value}
                        </Button>
                      :
                        <></>
                  }
                </StyledTableCell>
              )}
            </StyledTableRow>
          )}
        </TableBody>
      </MuiTable>
      {(systemLoading)  &&
        <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
          <CircularProgress size='100px' />
        </Box>
      }
      {
        !req?.[0] && !systemLoading &&
        <Box sx={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
          <Typography>Nenhum resultado encontrado</Typography>
        </Box>
      }
    </TableContainer>
  )
}
