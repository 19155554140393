import { Signup } from "pages"

export const signup = {
  path: 'signup',
  showMenu: false,
  authenticated: false,
  urls: [{
    path: '/',
    component: <Signup />
  }]
}
