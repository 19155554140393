import { AutofieldProvider, LoginProvider, PathProvider } from "contexts"
import { SystemRouter } from 'core'
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom"

export const App = () => (
      <LoginProvider>
        <PathProvider>
          <Router>
            <AutofieldProvider>
              <SystemRouter />
            </AutofieldProvider>
          </Router>
        </PathProvider>
      </LoginProvider>
)
