import { Divider, List, Toolbar } from "@mui/material"
import { router } from "core"
import { Item } from "./components"
import { useAutofieldContext } from "contexts"

export const Drawer = () => {
  const { autofieldsRoutes } = useAutofieldContext()

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {autofieldsRoutes && [...autofieldsRoutes, ...router].map((route, routeIdx) => (
          route.menu ? 
            route.urls?.find(url => url.menu) ?
              <>
                <Item icon={route.icon} key={`item-${routeIdx}-main`} text={route.menu} urlArr={[route.path]} />
                {
                  route.urls.map((url, urlIdx) => (
                    url.menu && <Item icon={url.icon} key={`item-${routeIdx}-${urlIdx}`} text={url.menu} urlArr={[route.path, url.path]} sx={{ paddingLeft: '50px' }} />
                  ))
                }
                <Divider />
              </>
            :
              <>
                <Item icon={route.icon} key={`item-${routeIdx}`} text={route.menu} urlArr={[route.path]} />
                <Divider />
              </>
              
          : undefined
        ))}
      </List>
    </div>
  )
}
