import { useCityFetcher, useStateFetcher } from "services"
import { Form } from "components"

export const Account = () => {
  const { states } = useStateFetcher()
  const { cities, fetchCities } = useCityFetcher()
  
  return (
    <Form
      endpoint="/accounts"
      loadValues={true}
      blocks={[{
        name: 'Informações Cadastrais',
        fields: [{
          label: 'Nome',
          name: 'name',
          type: 'text',
          maxLenght: 100,
          minLenght: 2,
          required: true
        }, {
          label: 'CPF',
          name: 'cpf',
          type: 'cpf',
          mask: '000.000.000-00',
          maxLenght: 11,
          minLenght: 11,
          required: true
        }, {
          label: 'Telefone',
          name: 'phone',
          type: 'phone',
          mask: '(00)00000-0000',
          maxLenght: 11,
          minLenght: 11,
          required: true
        }]
      }, {
        name: 'Endereço',
        fields: [[{
          label: 'Cep',
          name: 'zipCode',
          type: 'zipCode',
          required: true,
          mask: '00000-000'
        }, {
          label: 'Estado',
          name: 'stateId',
          type: 'select',
          required: true,
          options: states?.map((state) => ({
            label: state.name,
            value: state.id
          }))
        }, {
          label: 'Cidade',
          name: 'cityId',
          type: 'select',
          required: true,
          fetch: {
            fetcher: fetchCities,
            field: 'stateId'
          },
          options: cities?.map((city) => ({
            label: city.name,
            value: city.id
          }))
        }], [{
          label: 'Bairro',
          name: 'district',
          type: 'text',
          required: true,
          minLenght: 2,
          maxLenght: 100
        }, {
          label: 'Rua',
          name: 'street',
          type: 'text',
          required: true,
          minLenght: 2,
          maxLenght: 100
        }, {
          label: 'Número',
          name: 'number',
          type: 'text',
          required: true,
          minLenght: 2,
          maxLenght: 100
        }]]
      }]}
      submitButtonLabel="Salvar"
      sucessMessage="Alterações salvas com sucesso"
      errorMessage="Erro ao salvar alterações"
    />
  )
}